import React from "react";
import BlockFull from "../components/BlockFull";
import BlockTitle from "../components/BlockTitle";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <BlockFull>
      <BlockTitle>Etsimääsi sivua ei löytynyt</BlockTitle>
    </BlockFull>
  </Layout>
);

export default NotFoundPage;
